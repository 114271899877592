<template>
  <div>
    <v-data-table :loading="isLoading('get:matmed')"  :items="filteredData" :search="search" :headers="headers" :items-per-page="-1" height="900px" fixed-header>
      <template v-slot:top>
        <v-row class="mt-4" dense>
          <v-col >
            <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable />
          </v-col>
          <v-col class="text-end">
              <v-badge left bordered overlap :content="activeFilters.length">
                  <v-btn class="mr-4 elevation-0" text v-on:click="showFilters = !showFilters">
                  <v-icon left>
                      mdi-filter
                  </v-icon>
                  Filtros
                  </v-btn>
              </v-badge>
              <v-btn class="mr-4 elevation-0" text @click="atualizar"> <v-icon left>mdi-reload</v-icon> Atualizar </v-btn>
              <v-btn color="success" class="elevation-0" @click="dialog.create = true"> <v-icon left> mdi-plus </v-icon>  Novo </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card outlined v-show="showFilters" dense>
            <v-row dense class="py-2 px-4 align-center">
              <!-- Filtro à esquerda -->
              <v-col cols="2">
                <v-select hide-details :items="statusOptions" label="Status" item-text="label" item-value="value"
                    v-model="filters.is_active.value" outlined dense />
              </v-col>
              
              <!-- Botão "Aplicar Filtros" à direita -->
              <v-col class="text-right">
                <v-btn color="success" elevation="0" @click="applyFilters">
                  Aplicar filtros
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>

      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn icon @click="onSelectRow(item, 'update')">
          <v-icon color="green">
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
          <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
        </v-chip>
      </template>
      <template v-slot:[`item.classificacao.nome`]="{ item }">
        <span :style="{ color: item.classificacao.id === 2  ? 'red' : '', 'font-weight': item.classificacao.id === 2 ? 'bold' : 'normal' }">
          {{ item.classificacao.nome }}
        </span>
      </template>
      <template v-slot:[`item.unidade_medida_estoque`]="{ item }">  <span> {{item.unidade_medida_estoque.nome}} - {{item.unidade_medida_estoque.termo}} </span> </template>
      <template v-slot:[`item.apresentacao`]="{ item }">  <span> {{item.apresentacao_prescricao.nome}} - {{item.apresentacao_prescricao.abreviatura}} </span> </template>
     <template v-slot:[`item.enderecos`]="{ item }">
        <span v-if="item.enderecos && item.enderecos.length">
          <span v-for="(endereco, index) in item.enderecos" :key="endereco.id">
            R: {{ endereco.rua }} / P: {{ endereco.predio }} / A: {{ endereco.apt }}
            <span v-if="index < item.enderecos.length - 1"> ou </span>
          </span>
        </span>
        <span v-else>-</span> <!-- Se não houver endereço, exibir "-" -->
      </template>
      <template v-slot:[`item.nome`]="{ item }">  <span> {{item.nome_substancia}} - {{item.forma_farmaceutica.nome}} </span> </template>
      <template v-slot:[`item.padrao`]="{ item }">  <span> {{ item.padrao ? 'Sim' : 'Não'}} </span> </template>
      <template v-slot:[`item.manipulado`]="{ item }">  <span> {{ item.manipulado ? 'Sim' : 'Não'}} </span> </template>
      <template v-slot:[`item.alto_risco`]="{ item }"> <span :style="{ color: item.alto_risco ? 'red' : 'inherit', 'font-weight': item.alto_risco ? 'bold' : 'normal' }">  {{ item.alto_risco ? 'Sim' : 'Não'}} </span> </template>
    </v-data-table>
    <v-dialog v-model="dialog.create" v-if="dialog.create" max-width="90%">
      <v-card>
        <v-card-title class="blod_color">
          Novo Medicamento:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <NovoMedicamento :currentItem="newItem" :loading="isLoading('post:matmed')" :onSubmit="createMatMed" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.update" v-if="dialog.update" max-width="90%">
      <v-card>
        <v-card-title class="blod_color">
          Editar Medicamento: Id: {{ selectedRow.id}} - {{selectedRow.nome_substancia}}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <EditarMedicamento :currentItem="selectedRow" :loading="isLoading('put:matmed')" :onSubmit="updateEquipamento" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import EditarMedicamento from './EditarMedicamento.vue';
import NovoMedicamento from './NovoMedicamento.vue';
import './../../../../assets/css/styles.css';

export default {
  name: "Medicamento",
  data() {
    return UtilsFunc.withCRUDUtils({
      newItem: { ativo:1 },
      headers: [
        { text: "Editar", value: "edit", width: 50 },
        { text: "Id", value: "id", width: 30 },
        { text: "Substância", value: "nome" },
        { text: "Padrão", value: "padrao" },
        { text: "Alto Risco", value: "alto_risco" },
        { text: "Calssificação", value: "classificacao.nome" },
        { text: "Manipulado", value: "manipulado" },
        { text: "Apresentação Prescrição", value: "apresentacao" },
        { text: "Apresentação Estoque", value: "unidade_medida_estoque" },
        { text: "Endereço", value: "enderecos", width: 150 },
        { text: "QTD. Estoque", value: "" },
        { text: "Status", value: "ativo" },
      ],
      defaultSort: {
        name: 'ativo',
        isDesc: true
      },
      statusOptions: [
        { label: "Ativo", value: true },
        { label: "Inativo", value: false }
      ],
      matmed: [],
      showFilters: false,
      activeFilters: [],
      filters: {
        is_active: { value: true, compareType: 'equal', prop: 'ativo' }
      },
    });
  },
  methods: {
    async getMatMed() {
      const LOADING_NAME = "get:matmed";
      this.setLoading(LOADING_NAME);
      try {
        const response = await api.get("/matmed/matmeds/");
        this.matmed = response.data.filter((item) => item.tipo.id === 1);
        this.applyFilters(); // Aplica filtros após o carregamento
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
      // console.log(this.matmed)
    },
    async createMatMed(fields) {
      const LOADING_NAME = "post:matmed";
      this.setLoading(LOADING_NAME);
      try {
        // console.log(fields)
        await api.post("/matmed/matmed_criar/", fields);
        await this.getMatMed()
        this.dialog.create = false
        this.$toast.success('Medicamento criada com sucesso!')
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async updateEquipamento(fields) {
      const LOADING_NAME = "put:matmed";
      this.setLoading(LOADING_NAME);
      try {
        await api.put(`/matmed/matmed/${fields.id}/`, fields);
        await this.getMatMed()
        this.dialog.update = false
        this.$toast.success('Medicamento atualizado com sucesso!')
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async applyFilters() {
      this.search = "";
      this.activeFilters = Object.entries(this.filters).reduce((acc, [key, filter]) => {
        if (filter.value !== undefined && filter.value !== null) {
          acc[key] = { ...filter };
        }
        return acc;
      }, {});

      this.$forceUpdate(); // Força a atualização do Vue para refletir os filtros aplicados
    },

    clearFilters() {
      this.filters = {
        is_active: { value: true, compareType: "equal", prop: "ativo" }
      };
      this.activeFilters = {}; // Garante que nenhum outro filtro esteja aplicado
      this.$forceUpdate(); // Força atualização da tela para refletir a mudança
    },
    atualizar() {
      this.matmed = [];
      this.search = "";
      this.showFilters = false;
      this.clearFilters();
      setTimeout(() => {
        this.inicializar(); // Carrega os dados após a limpeza
      }, 100);
    },
    inicializar(){
      this.getMatMed();
    },
  },
  computed: {
    filteredData() {
      let data = [...this.matmed];

      // Aplica os filtros SOMENTE se o botão "Aplicar filtros" foi pressionado
      if (this.activeFilters.is_active?.value !== undefined) {
        data = data.filter(item => item.ativo === this.activeFilters.is_active.value);
      }

      if (this.search) {
        const searchTerm = this.search.toLowerCase().trim();
        data = data.filter((item) => {
          const nome = item.nome_substancia?.toLowerCase() || "";
          const tarja = item.tarja?.nome?.toLowerCase() || "";
          // const tipologia = item.tipologia?.nome?.toLowerCase() || "";

          return (
            nome.includes(searchTerm) ||
            tarja.includes(searchTerm) 
            // tipologia.includes(searchTerm)
          );
        });
      }

      return data.sort((a, b) => {
        if (a.ativo === b.ativo) {
          return a.nome_substancia.localeCompare(b.nome_substancia);
        }
        return b.ativo - a.ativo;
      });
    },
  },
  mounted() {
    this.inicializar();
  },
  components: { EditarMedicamento, NovoMedicamento }
}
</script>
